import nextSiblingsMatching from './nextSiblingsMatching';

/**
 * @param {Element} containerElement container
 * @param {string} selector selector
 * @param {boolean} allowNested allow nested elements
 * @param {number} position position
 * @param {Array} groups groups
 * @returns {Element[][]} groups
 */
function internalFindGroups(containerElement, selector, allowNested = false, position = 0, groups = []) {
	let elements = [...containerElement.querySelectorAll(selector)];
	if (allowNested !== true) {
		elements = elements.filter(element => element.parentNode.closest(selector) === null);
	}

	const firstElement = elements[position];
	if (!firstElement) {
		return groups;
	}

	// assumption(PG): siblings cannot be nested when the prime sibling
	const group = [firstElement, ...nextSiblingsMatching(firstElement, selector)];
	groups.push(group);

	// note(PG): might fail when nested elements are skipped
	const nextIndex = position + group.length;
	if (elements[nextIndex]) {
		groups = internalFindGroups(containerElement, selector, allowNested, nextIndex, groups);
	}

	return groups;
}

/**
 * find groups of elements by selector where groups are direct sibling elements (nextSibling) matching the given selector without
 *
 * @param {Element} containerElement container element
 * @param {string} selector selector to match
 * @param {boolean} allowNested allow nested elements
 * @returns {Element[][]} groups of elements
 */
export default function findGroupsMatching(containerElement, selector, allowNested = false) {
	return internalFindGroups(containerElement, selector, allowNested);
}
