// @preval
/* eslint-disable import/no-commonjs */

const resolveConfig = require('tailwindcss/resolveConfig');

const tailwindConfig = require('../../../tailwind.config');

const fullConfig = resolveConfig(tailwindConfig);

module.exports.screens = fullConfig.theme.screens;
