import React, {useEffect, useRef} from 'react';
import {AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';

import Icon from './Icon';

function ContentEffectAccordionItem({uuid, heading, headingLevel, contentElements, headingContentElements, focused}) {
	const panelRef = useRef();
	const headingContentRef = useRef();
	const headingRef = useRef();

	useEffect(function () {
		/** @var {Element} panelElement */
		const panelElement = panelRef.current;
		contentElements.forEach(ce => panelElement.append(ce));
	}, [contentElements]);

	useEffect(function () {
		/** @var {Element} headingContentWrapperElement */
		const headingContentWrapperElement = headingContentRef.current;
		if (headingContentElements.length && headingContentWrapperElement) {
			headingContentElements.forEach(ce => headingContentWrapperElement.append(ce));
		}
	}, [headingContentElements]);

	useEffect(function () {
		/** @var {Element} panelElement */
		const panelElement = panelRef.current;
		if (focused) {
			/** @var {Element} headingElement */
			const headingElement = headingRef.current;
			headingElement.scrollIntoView({behavior: 'smooth', block: 'start'});
			panelElement.classList.add('jki-accordion__content-wrapper--focused');

			const timeout = setTimeout(() => {
				panelElement.classList.remove('jki-accordion__content-wrapper--focused');
			}, 10_000);

			return () => {
				clearTimeout(timeout);
			};
		}

		panelElement.classList.remove('jki-accordion__content-wrapper--focused');

		return () => undefined;
	}, [focused]);

	return (
		<AccordionItem uuid={uuid} className="jki-accordion__item">
			<AccordionItemHeading className="jki-accordion__heading" aria-level={headingLevel}>
				<AccordionItemButton className="jki-accordion__button print:px-4 bg-gray-50 cursor-pointer outline-none border-t print:border-t-0">
					<div className="flex w-full">
						<Icon icon="arrow-small" className="jki-accordion__arrow print:hidden flex-shrink-0 fill-water-800" />
						<span ref={headingRef} className="flex-shrink text-base lg:text-lg block font-semibold py-2"
							  dangerouslySetInnerHTML={{__html: heading}} />
					</div>
					<div
						className={`${headingContentElements.length ? 'block' : 'hidden'} mx-4 ml-10 py-px jki-accordion__heading-content-wrapper`}
						ref={headingContentRef}
					/>
				</AccordionItemButton>
			</AccordionItemHeading>

			<AccordionItemPanel className="jki-accordion__panel print:block">
				<div className="jki-accordion__content-wrapper clearfix" ref={panelRef} />
			</AccordionItemPanel>
		</AccordionItem>
	);
}

export default ContentEffectAccordionItem;
