export default function getHeadingLevelFromElement(element) {
	if (element.getAttribute('aria-level')) {
		return parseInt(element.getAttribute('aria-level'), 10);
	}

	const match = element.tagName.match(/^H(\d)$/);
	if (match !== null) {
		return parseInt(match[1], 10);
	}

	return null;
}

