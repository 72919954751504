import Swiper from 'swiper/core/core';
import Navigation from 'swiper/modules/navigation/navigation';

import {lock, unlock, unsetRelative} from './scrollDetect';

const SELECTOR = '.jki-on-page-nav';

function renderNav(containerElement) {
	const intersectingIds = new Set();
	const contentIntersectionObserver = new IntersectionObserver(entries => {
		let aboveTheFoldId = null;
		entries.forEach(entry => {
			intersectingIds[entry.isIntersecting ? 'add' : 'delete'](entry.target.id);
			if (entry.boundingClientRect.top < 0) {
				aboveTheFoldId = entry.target.id;
			}
		});

		[...containerElement.querySelectorAll('.jki-on-page-nav__item--active')].forEach(el => {
			el.classList.remove('jki-on-page-nav__item--active');
		});

		const activeIds = intersectingIds.size ? Array.from(intersectingIds) : aboveTheFoldId ? [aboveTheFoldId] : [];
		activeIds.forEach(id => {
			const linkEl = containerElement.querySelector(`a[href="#${id}"]`);
			linkEl.classList.add('jki-on-page-nav__item--active');
		});

	}, {});

	const swiper = new Swiper(containerElement.querySelector('.swiper'), {
		modules: [Navigation],
		slidesPerView: 'auto',
		spaceBetween: 0,
		freeMode: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		mousewheel: {
			forceToAxis: true,
			releaseOnEdges: true,
		},
		a11y: {
			containerMessage: null,
			containerRoleDescriptionMessage: null,
			itemRoleDescriptionMessage: null,
			prevSlideMessage: 'Vorherigen Eintrag',
			nextSlideMessage: 'Nächsten Eintrag',
			firstSlideMessage: 'Erster Eintrag',
			lastSlideMessage: 'Letzter Eintrag',
			paginationBulletMessage: 'Gehe zu Eintrag {{index}}',
			slideLabelMessage: '{{index}} / {{slidesLength}}',
			slideRole: 'group',
		},
	});
	swiper.on('unlock', updateOverflowClass);
	swiper.on('lock', updateOverflowClass);
	updateOverflowClass();

	const linkElements = containerElement.querySelectorAll('a[href^="#"]');
	linkElements.forEach(linkEl => {
		const hash = linkEl.getAttribute('href');
		const targetEl = document.querySelector(hash);
		contentIntersectionObserver.observe(targetEl);
		linkEl.classList.add('jki-on-page-nav__item');

		const slideEl = linkEl.closest('.swiper-slide');
		const slideIndex = [...slideEl.parentElement.children].indexOf(slideEl);

		linkEl.addEventListener('click', (event) => {
			lock();
			unsetRelative();

			setTimeout(() => {
				unlock();
			}, 2000);

			targetEl.tabIndex = -1;
			targetEl.focus({preventScroll: true});

			swiper.slideTo(slideIndex);

			if (targetEl && 'scrollIntoView' in targetEl) {
				const {top, height} = containerElement.getBoundingClientRect();
				const offset = top + height + 20;

				event.preventDefault();
				targetEl.style.scrollMarginTop = `${offset}px`;
				targetEl.scrollIntoView({behavior: 'smooth', block: 'start'});
				history.pushState({}, '', hash);
			}
		});
	});

	function updateOverflowClass() {
		containerElement.classList[swiper.isLocked ? 'remove' : 'add']('jki-on-page-nav--has-overflow');
		swiper.updateSlides();
	}
}

export function render(container = document.body) {
	container.querySelectorAll(SELECTOR).forEach(renderNav);
}
