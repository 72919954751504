import React from 'react';

import SvgSymbol from './SvgSymbol';

function Icon({icon, className, iconMap, extension, altText, size = 'default'}) {
	return (
		<React.Fragment>
			<svg className={`jki-svg-icon jki-svg-icon--size-${size} [ ${className} ]`} aria-hidden="true" role="img">
				<SvgSymbol icon={icon} iconMap={iconMap} />

				{extension ? (
					<g transform="translate(0, -3)">
						{/* background using stroke */}
						<text x="100%" y="100%" fontWeight="bold" fontSize="11" textAnchor="end" strokeWidth="5" stroke="white"
							  fill="white">{extension}
						</text>
						<text x="100%" y="100%" fontWeight="bold" fontSize="11" textAnchor="end">{extension}</text>
					</g>
				) : null}
			</svg>

			{altText ? (
				<span className="sr-only">{altText}</span>
			) : null}
		</React.Fragment>
	);
}

export default Icon;
