import $ from 'jquery';
import 'devbridge-autocomplete/dist/jquery.autocomplete';

const FORM_SELECTOR = 'form[data-suggest]';
const SUGGEST_INPUT_SELECTOR = '.tx-solr-suggest';

$(document).ready(() => {
	$(FORM_SELECTOR).each(function () {
		const $form = $(this);
		const $searchBox = $form.find(SUGGEST_INPUT_SELECTOR);
		//$searchBox.focus();

		// when no specific container found, use the form as container
		const $container = $searchBox.length === 0 ? $form : $searchBox;
		$container.css('position', 'relative');

		// Prevent submit of empty search form
		$form.on('submit', (e) => {
			const $suggestElement = $form.find(SUGGEST_INPUT_SELECTOR);

			if ($suggestElement.val() === '') {
				e.preventDefault();
				$suggestElement.focus();
			}
		});

		$searchBox.devbridgeAutocomplete({
			serviceUrl: $form.data('suggest'),
			dataType: 'jsonp',
			ajaxSettings: {
				jsonp: 'tx_solr[callback]',
			},
			paramName: 'tx_solr[queryString]',
			groupBy: 'category',
			maxHeight: 1000,
			autoSelectFirst: false,
			width: $container.outerWidth(),
			onSelect: function (suggestion) {
				// go to link when selecting found result
				if (suggestion.data.link) {
					location.href = suggestion.data.link;
					// else trigger form submit (do search)
				} else {
					$form.trigger('submit');
				}
			},
			transformResult: function (response) {
				if (!response.suggestions) return {suggestions: []};
				let firstSuggestion;
				const result = {
					suggestions: $.map(response.suggestions, (count, suggestion) => {
						if (!firstSuggestion) firstSuggestion = suggestion;
						return {value: suggestion, data: {category: 'suggestion', count: count}};
					})
				};

				$.each(response.documents, (key, dataObject) => {
					dataObject.category = $form.data('suggest-header') ?
						$form.data('suggest-header') :
						'Top results';

					// if a group is set we try to get a label
					if (dataObject.group) {
						dataObject.category = $form.data('suggest-header-' + dataObject.group) ?
							$form.data('suggest-header-' + dataObject.group) :
							dataObject.group;
					}

					result.suggestions.push({
						value: firstSuggestion,
						data: dataObject,
					});
				});

				// filter result, remove all entries with sys_file_metadata type
				result.suggestions = result.suggestions.filter((suggestion) => suggestion.data.type !== 'sys_file_metadata');

				// 1 result means not result for given input; then we don't show is as suggestion
				// also prevents autosubmit when selecting input box
				if (result.suggestions.length <= 1) {
					result.suggestions = [];
				}
				return result;
			},
			beforeRender: function (container) {
				// remove first group header
				container.find('.autocomplete-group:first').remove();
				container.addClass('tx-solr-autosuggest');

				// add active class to container
				$container.parent().addClass('autocomplete-active').fadeIn();
			},
			formatResult: function (suggestion, currentValue) {
				// Do not replace anything if there current value is empty
				if (!currentValue) {
					return suggestion.value;
				}
				const pattern = '(' + $.Autocomplete.utils.escapeRegExChars(currentValue.trim()) + ')';
				// normal suggestion
				if (suggestion.data.category === 'suggestion') {
					return suggestion.value
						.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
						.replace(/&/g, '&amp;')
						.replace(/</g, '&lt;')
						.replace(/>/g, '&gt;')
						.replace(/"/g, '&quot;')
						.replace(/&lt;(\/?strong)&gt;/g, '<$1>');

					// results
				} else {
					const title = suggestion.data.title
						.replace(new RegExp(pattern, 'gi'), '<em>$1</em>')
						.replace(/&/g, '&amp;')
						.replace(/</g, '&lt;')
						.replace(/>/g, '&gt;')
						.replace(/"/g, '&quot;')
						.replace(/&lt;(\/?em)&gt;/g, '<$1>');

					if (suggestion.data.type === 'sys_file_metadata') {
						return false;
					} else {
						return `
							<div class="${suggestion.data.type}">
								<a href="${suggestion.data.link}" class="link-internal">${title}</a>
							</div>`;
					}
				}
			}
		});

		$searchBox.on('blur', function () {
				$container.parent().removeClass('autocomplete-active');
				const $box = $(this);
				setTimeout(() => $box.devbridgeAutocomplete('hide'), 200);
			});
	});
});

