const SELECTOR = '.js-jki-sticky-detect';

function renderStickyDetect(element) {
	const offset = -1 * (parseInt(getComputedStyle(element).top, 10) + 1);

	const observer = new IntersectionObserver(
		([entry]) => {
			element.classList[entry.intersectionRatio < 1 ? 'add' : 'remove']('sticky-pinned');
		},
		{
			rootMargin: `${offset}px 0px 0px 0px`,
			threshold: [1],
		}
	);
	observer.observe(element);
}

export function render(container = document.body) {
	container.querySelectorAll(SELECTOR).forEach(renderStickyDetect);
}
