import {screens} from './theme';

const breakpointNames = Object.keys(screens);

function mapTailwindBreakpointToMediaQuery({value}) {
	if (typeof value === 'number') {
		return `screen and (min-width: ${value})`;
	}

	if (typeof value === 'object') {
		if (value.raw !== undefined) {
			return value.raw;
		}
		if (value.min !== undefined) {
			return `screen and (min-width: ${value.min})`;
		}
	}

	return false;
}

export function responsiveElementRender(baseClass, element, activate, deactivate) {
	const classes = [...element.classList].filter(n => n.includes(baseClass));
	let breakpoints = [];
	let enableDefault = false;

	classes.forEach(className => {
		const [base, variant] = className.split(':').reverse();
		if (!variant) {
			breakpoints = []; // overrides everything before
			enableDefault = base === baseClass;
		} else if (breakpointNames.includes(variant)) {
			breakpoints.push({value: screens[variant], not: base === `no-${baseClass}`});
		}
	});

	if (!breakpoints.length) {
		if (enableDefault) {
			activate();
		}

		return function cleanup() {
			if (enableDefault) {
				deactivate();
			}
		};
	}

	const queries = breakpoints.map(mapTailwindBreakpointToMediaQuery).filter(Boolean);

	const mqls = queries.map(query => window.matchMedia(query));
	const checkMqls = () => {
		let matches = enableDefault;
		mqls.forEach((mql, i) => {
			if (mql.matches) {
				matches = !breakpoints[i].not;
			}
		});

		if (matches) {
			activate();
		} else {
			deactivate();
		}
	};
	mqls.forEach(mql => mql.addEventListener('change', checkMqls));
	checkMqls();

	return function cleanup() {
		mqls.forEach(mql => mql.removeEventListener('change', checkMqls));
		deactivate();
	};
}
