import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactModal from 'react-modal';

import createEventHandlerMatching from '@neonaut/lib-js/es/dom/events/create-event-handler-matching';

function FullModal({label, id, html, onOpen, onClose, onAfterOpen, modalOptions = {}}) {
	const contentRef = useRef();
	const [modalIsOpen, setIsOpen] = useState(false);

	const openModal = useCallback(function () {
		if (onOpen) {
			onOpen({id: id, contentElement: contentRef.current});
		}
		setIsOpen(true);
	}, [id, onOpen, setIsOpen]);

	const closeModal = useCallback(function () {
		if (onClose) {
			onClose({id: id, contentElement: contentRef.current});
		}
		setIsOpen(false);
	}, [id, onClose, setIsOpen]);

	const handleAfterOpenModal = useCallback(function afterOpenModal() {
		if (onAfterOpen) {
			onAfterOpen({id: id, contentElement: contentRef.current});
		}
	}, [id, onAfterOpen]);

	useEffect(function () {
		const handleClick = createEventHandlerMatching('[data-toggle="modal"][data-target="#' + id + '"], [data-toggle="modal"][href="#' + id + '"]', function (e) {
			e.preventDefault();

			if (modalIsOpen) {
				closeModal();
			} else {
				openModal();
			}
		});

		document.addEventListener('click', handleClick);
		return function () {
			document.removeEventListener('click', handleClick);
		};
	}, [id, openModal, closeModal, modalIsOpen]);

	return (
		<ReactModal
			portalClassName="jki-modal-portal"
			overlayClassName={{base: 'jki-modal-overlay', afterOpen: 'jki-modal-overlay--after-open', beforeClose: 'jki-modal-overlay--before-close'}}
			className={{base: 'jki-modal', afterOpen: 'jki-modal--after-open', beforeClose: 'jki-modal--before-close'}}
			bodyOpenClassName="jki--modal-open"
			htmlOpenClassName="jki--modal-open"
			closeTimeoutMS={400}
			{...modalOptions}
			isOpen={modalIsOpen}
			onAfterOpen={handleAfterOpenModal}
			onRequestClose={closeModal}
			contentLabel={label}
		>
			<div ref={contentRef} dangerouslySetInnerHTML={{__html: html}} />
		</ReactModal>
	);
}

export default FullModal;
