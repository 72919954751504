import $ from 'jquery';

function init() {
	$('.tx-solr-facet-hidden').hide();
	$('a.tx-solr-facet-show-all')
		.on('click', function (e) {
			e.preventDefault();

			const $link = $(this);
			const $linkWrapper = $link.parent();

			if (!$linkWrapper.siblings('.tx-solr-facet-hidden:visible').length) {
				$linkWrapper.siblings('.tx-solr-facet-hidden').show();
				$link.text($link.data('label-less'));
			} else {
				$linkWrapper.siblings('.tx-solr-facet-hidden').hide();
				$link.text($link.data('label-more'));
			}
		});

	const $facets = $('.facet-filter-box').closest('.facet');
	$facets.each(function () {
		const $facet = $(this);
		const $searchBox = $facet.find('.facet-filter-box');
		const $searchItems = $facet.find('.facet-filter-item');

		$searchBox.on('keyup', () => {
			const value = $searchBox.val().toLowerCase();

			$searchItems.each(function () {
				const $filteredItem = $(this);
				$filteredItem.toggle($filteredItem.text().toLowerCase().indexOf(value) > -1);
			});
		});
	});
}

$(document).ready(() => {
	$('body').on('tx_solr_updated', init);
	init();
});
