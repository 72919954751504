export const COLLAPSED_ACTIVE_CLASS_NAME = 'jki-collapsed--active';
export const COLLAPSED_TRANSITION_DURATION = 500;
export const COLLAPSED_TRIGGER_REMOVE_DELAY = 500;
export const collapsedTriggerLabelActive = document.documentElement.lang === 'de' ?
	'Weiterlesen …' :
	'Read more…';

function getRealHeight(elem, display = 'block') {
	const displayBefore = elem.style.display;
	elem.style.display = display;
	const height = elem.scrollHeight;
	elem.style.display = displayBefore;
	return `${height}px`;
}

function renderCollapsed(element) {
	const wrapper = document.createElement('div');
	wrapper.className = 'jki-collapsed__wrapper';
	wrapper.setAttribute('hidden', 'hidden');

	const trigger = document.createElement('button');
	trigger.type = 'button';
	trigger.className = 'jki-collapsed__trigger';
	trigger.textContent = collapsedTriggerLabelActive;

	element.classList.add(COLLAPSED_ACTIVE_CLASS_NAME);
	[...element.children].forEach(childEl => {
		childEl.remove();
		wrapper.append(childEl);
	});
	element.append(wrapper);
	element.append(trigger);

	function onClick() {
		trigger.removeEventListener('click', onClick);

		wrapper.style.height = getRealHeight(wrapper);
		element.classList.remove(COLLAPSED_ACTIVE_CLASS_NAME);
		wrapper.removeAttribute('hidden');

		window.setTimeout(() => {
			trigger.remove();
		}, COLLAPSED_TRIGGER_REMOVE_DELAY);

		window.setTimeout(() => {
			wrapper.style.height = '';
		}, COLLAPSED_TRANSITION_DURATION);
	}

	trigger.addEventListener('click', onClick);
}

export function render() {
	document.body.querySelectorAll('.nn-collapsed')
		.forEach(element => renderCollapsed(element));
}
