import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';

const SELECTOR_GALLERY = '.ce-gallery';
const SELECTOR_FIGURE = 'figure';
const SELECTOR_LINK = '.typo3-fancybox';

const translation = document.documentElement.lang === 'de' ? {
	closeTitle: 'Schließen',
	zoomTitle: 'Vergrößern/Verkleinern (z)',
	arrowPrevTitle: 'Vorheriges',
	arrowNextTitle: 'Nächstes',
} : {
	closeTitle: 'Close',
	zoomTitle: 'Zoom (z)',
	arrowPrevTitle: 'Previous',
	arrowNextTitle: 'Next',
};

function registerCustomUi(lightbox) {
	lightbox.pswp.ui.registerElement({
		name: 'custom-caption',
		order: 9,
		isButton: false,
		appendTo: 'root',
		html: '',
		onInit: (el, pswp) => {
			lightbox.pswp.on('change', () => {
				const currSlideElement = lightbox.pswp.currSlide.data.element;
				let captionHTML = '';
				if (currSlideElement) {
					const figureCaptionElement = currSlideElement.closest('figure').querySelector('figcaption');
					if (figureCaptionElement) {
						captionHTML = figureCaptionElement.innerHTML;
					}
				}
				el.classList[captionHTML ? 'remove' : 'add']('pswp__custom-caption--hidden');
				el.classList[captionHTML ? 'add' : 'remove']('pswp__custom-caption--visible');
				el.innerHTML = captionHTML;
			});
		},
	});
}

function getClickedIndex(e) {
	const clickedFigure = e.target.closest(SELECTOR_FIGURE);
	const galleryElement = e.target.closest(SELECTOR_GALLERY);
	if (!clickedFigure || !galleryElement) {
		return -1;
	}

	let index = -1;
	[...galleryElement.querySelectorAll(SELECTOR_FIGURE)].some((figure, i) => {
		if (figure === clickedFigure) {
			index = i;
			return true;
		}

		return false;
	});

	return index;
}

export function render() {
	const galleryElements = [...document.querySelectorAll(SELECTOR_GALLERY)];
	galleryElements.forEach((galleryElement, i) => {
		if (!galleryElement.querySelectorAll(SELECTOR_LINK).length) {
			return;
		}

		const lightbox = new PhotoSwipeLightbox({
			...translation,
			gallery: galleryElement,
			children: SELECTOR_LINK,
			pswpModule: PhotoSwipe,
			getClickedIndexFn: getClickedIndex,
			preloadFirstSlide: true,
			//showHideAnimationType: 'zoom', // 'zoom'|'fade'|'none'
			//showAnimationDuration: 333,
			//hideAnimationDuration: 333,
		});
		lightbox.on('uiRegister', () => registerCustomUi(lightbox));
		lightbox.init();
	});
}
