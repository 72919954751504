import $ from 'jquery';

import {lock, unlock} from './scrollDetect';

const SELECTOR_LIST = '.frame-a-to-z ul.links';
const SELECTOR_ITEM = '.links__item';
const SELECTOR_TITLE = '.link__title';

const TEMPLATE_REGISTER = '<ul class="jki-a-to-z__register md:whitespace-nowrap md:border-b" />';
const TEMPLATE_REGISTER_ITEM = '<li class="jki-a-to-z__register__item"><button class="jki-a-to-z__register__button w-10 md:w-6" role="button" title="Zum Buchstaben ${letter} in der Liste springen">${letter}</button></li>';
const TEMPLATE_LIST = '<ul />';
const TEMPLATE_HEADLINE = '<h3 class="jki-a-to-z__headline" />';

$(() => {
	$(SELECTOR_LIST).each((i, container) => {
		const $list = $(container);
		const $items = $list.find(SELECTOR_ITEM);

		const $wrapper = $('<div class="jki-a-to-z" />');
		$list.before($wrapper);

		const letters = [];
		let currentLetter = false;
		$items.each((j, item) => {
			const $item = $(item);
			const title = $item.find(SELECTOR_TITLE).text().trim().toUpperCase();

			if (title.length) {
				const letter = title[0];

				if (letter !== currentLetter) {
					const $nextItems = $item.nextAll(SELECTOR_ITEM).andSelf();

					const $newHeadline = $(TEMPLATE_HEADLINE);
					$newHeadline.text(letter);

					const $newList = $(TEMPLATE_LIST);
					$newList.attr('class', $list.attr('class'));
					$newList.addClass('jki-a-to-z__list');

					$nextItems.appendTo($newList);
					$wrapper.append($newHeadline);
					$wrapper.append($newList);

					letters.push({
						letter: letter,
						$headline: $newHeadline,
					});
					currentLetter = letter;
				}
			}
		});

		const $register = $(TEMPLATE_REGISTER);

		function removeFocusedClasses() {
			$('.jki-a-to-z__list--focused').removeClass('jki-a-to-z__list--focused');
		}

		let removeFocusedClassesTimeout;
		let removeScrollDetectLockTimeout;

		function scrollToHeadline({letter, $headline}) {
			lock();

			window.scrollTo({
				top: $headline[0].getBoundingClientRect().top
					+ window.pageYOffset - 100,
				behavior: 'smooth',
			});

			if (removeScrollDetectLockTimeout) {
				unlock();
				window.clearTimeout(removeScrollDetectLockTimeout);
			}
			if (removeFocusedClassesTimeout) {
				window.clearTimeout(removeFocusedClassesTimeout);
			}

			removeFocusedClasses();
			$headline.next().addClass('jki-a-to-z__list--focused');

			removeFocusedClassesTimeout = window.setTimeout(() => {
				removeFocusedClasses();
				removeFocusedClassesTimeout = null;
			}, 5000);
			removeScrollDetectLockTimeout = window.setTimeout(() => {
				unlock();
				removeScrollDetectLockTimeout = null;
			}, 3000);

			history.pushState({}, null, `#${encodeURI(letter.toLowerCase())}`);
		}

		$list.appendTo($wrapper);

		letters.forEach(letterInfo => {
			const {letter} = letterInfo;
			const $registerItem = $(TEMPLATE_REGISTER_ITEM.replace(/\${letter}/gi, letter));
			$registerItem.appendTo($register);

			// take over scrolling when NOT using keyboard. otherwise keep default behaviour (including setting focus)
			$registerItem.on('mouseup touchend', (e) => {
				e.preventDefault();
				e.stopPropagation();
				scrollToHeadline(letterInfo);
			});

			if (`#${letter.toLowerCase()}` === decodeURI(location.hash).toLowerCase()) {
				letterInfo.$headline.next().find('a').first().focus(); // manually focus first link in list
				scrollToHeadline(letterInfo);
			}
		});

		$register.prependTo($wrapper);
	});
});
