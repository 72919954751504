// add app code here

// legacy code (jquery):
import './modules/a-to-z';
import './modules/solr/facet-options-controller';
import './modules/solr/search-suggest-controller';

// 2021 code:
import './modules/scrollDetect';

import {render as renderStickyDetect} from './modules/stickyDetect';
import {render as renderAccordions} from './modules/accordions';
import {render as renderCarousels} from './modules/carousels';
import {render as renderCollapsed} from './modules/collapsed';
import {render as renderDropdownNav} from './modules/dropdownNav';
import {render as renderLightboxes} from './modules/lightboxes';
import {render as renderModals} from './modules/modals';
import {render as renderNavbar} from './modules/navbar';
import {render as renderNavigationModal} from './modules/navigationModal';
import {render as renderOnPageNav} from './modules/onPageNav';

renderOnPageNav();
renderAccordions();
renderCarousels();
renderCollapsed();
renderDropdownNav();
renderLightboxes();
renderModals();
renderNavbar();
renderNavigationModal();
renderStickyDetect();
