/**
 * Finds next direct siblings matching the selector. Stops when the first sibling does not match the selector.
 *
 * @param {Element} element element
 * @param {string} selector selector
 * @returns {Element[]} elements
 */
export default function nextSiblingsMatching(element, selector) {
	const matches = [];

	let currentElement = element;
	while (currentElement) {
		const nextElement = currentElement.nextElementSibling;
		currentElement = null;

		if (nextElement && nextElement.matches(selector)) {
			matches.push(nextElement);
			currentElement = nextElement;
		}
	}

	return matches;
}
