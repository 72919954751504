import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import FullModal from '../components/FullModal';

ReactModal.setAppElement('#root');

const prefix = 'jki-modal';
let globalIdCounter = 0;

let renderTarget;

export function getRenderTarget() {
	if (!renderTarget) {
		renderTarget = document.createElement('div');
		renderTarget.hidden = true;
		document.body.append(renderTarget);
	}

	return renderTarget;
}

export function parseModalTemplate(modalElement) {
	let id = modalElement.getAttribute('id');
	if (!id) {
		id = `${prefix}-${globalIdCounter++}`;
		modalElement.setAttribute('id', id);
	}

	const html = modalElement.innerHTML;
	const label = modalElement.getAttribute('aria-label');
	modalElement.remove();

	return {id: id, html: html, label: label};
}

export function render(container = document.body) {
	container.querySelectorAll('.js-jki-full-modal').forEach(function (modalElement) {
		const {id, html, label} = parseModalTemplate(modalElement);

		ReactDOM.render(<FullModal id={id} html={html} label={label} />, getRenderTarget());
	});
}
