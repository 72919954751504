import {addListener, getScrollState} from './scrollDetect';

function willTransition(element) {
	const style = window.getComputedStyle(element);
	return !!style.getPropertyValue('transition-property') &&
		parseFloat(style.getPropertyValue('transition-duration')) > 0;
}

function renderNavbar(element) {
	let scrollState = getScrollState();

	addListener(() => {
		const newScrollState = getScrollState();
		if (newScrollState.isUp !== scrollState.isUp && willTransition(element)) {
			element.classList.add('transition-active');
		}
		scrollState = newScrollState;
	});

	element.addEventListener('transitionend', () => {
		element.classList.remove('transition-active');
	});
}

export function render() {
	[...document.querySelectorAll('.js-jki-navbar')].forEach(renderNavbar);
}
