import Flickity from 'flickity';

import {responsiveElementRender} from '../helpers/responsiveElementRender';

const SELECTOR_CLASS = 'js-jki-carousel';

function renderCarousel(carouselElement) {
	let instance;

	responsiveElementRender(
		SELECTOR_CLASS,
		carouselElement,
		function init() {
			if (!instance) {
				instance = new Flickity(carouselElement, {
					// options
					//cellAlign: 'left',
					//contain: true,
					pageDots: false,
				});
			}
		},
		function destroy() {
			if (instance) {
				instance.destroy();
				instance = null;
			}
		}
	);
}

export function render(container = document.body) {
	container.querySelectorAll(`[class*="${SELECTOR_CLASS}"]`).forEach(renderCarousel);
}
