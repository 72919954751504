import React from 'react';

import {timestamp} from '../helpers/buildTime';

function SvgSymbol({icon, iconMap = 'base'}) {
	return (
		<React.Fragment>
			<use xlinkHref={`/assets/img/svg-sprite-${iconMap}.symbols.${timestamp}.svg#${icon}`} />
		</React.Fragment>
	);
}

export default SvgSymbol;
