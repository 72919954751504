import React, {useCallback, useRef} from 'react';
import {Accordion} from 'react-accessible-accordion';

import ContentEffectAccordionItem from './ContentEffectAccordionItem';

function ContentEffectAccordion({items, ...options}) {
	const domRef = useRef();
	const handleChange = useCallback(function handleChange(selectedIds) {
		if (!selectedIds.length) {
			return;
		}

		/** @type {Element} el */
		const el = domRef.current;
		const header = el.querySelector(`#accordion__heading-${selectedIds[0]}`);
		header.scrollIntoView({behavior: 'smooth', block: 'start'});
	}, []);

	const preExpanded = items
		.filter(item => item.focused)
		.map(item => item.uuid);

	return (
		<div ref={domRef}>
			<Accordion
				className="jki-accordion"
				preExpanded={preExpanded}
				{...options}
				onChange={handleChange}
			>
				{items.map((item) => <ContentEffectAccordionItem key={item.uuid} {...item} />)}
			</Accordion>
		</div>
	);
}

export default ContentEffectAccordion;
