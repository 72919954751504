import React from 'react';
import ReactDOM from 'react-dom';

import FullModal from '../components/FullModal';

import {getRenderTarget, parseModalTemplate} from './modals';

const modalOptions = {
	overlayClassName: {
		base: 'jki-navigation-modal-overlay',
		afterOpen: 'jki-navigation-modal-overlay--after-open',
		beforeClose: 'jki-navigation-modal-overlay--before-close',
	},
	className: {
		base: 'jki-navigation-modal',
		afterOpen: 'jki-navigation-modal--after-open',
		beforeClose: 'jki-navigation-modal--before-close',
	},
};

export function render() {
	const modalElement = document.getElementById('jki-navigation-modal');
	const {id, html, label} = parseModalTemplate(modalElement);

	ReactDOM.render(<FullModal id={id} html={html} label={label} modalOptions={modalOptions} />, getRenderTarget());
}
