import React from 'react';
import ReactDOM from 'react-dom';

import getHeadingLevelFromElement from '../helpers/dom/access/getHeadingLevelFromElement';
import findGroupsMatching from '../helpers/dom/traverse/findGroupsMatching';
import ContentEffectAccordion from '../components/ContentEffectAccordion';

const SELECTOR = '.frame-nn-accordion';

const CLASSNAME_SUB = 'frame-nn-accordion--sub';

const SELECTOR_HEADER = 'header';
const SELECTOR_HEADING = 'h1, h2, h3, h4, h5, h6, [aria-level]';

const DEFAULT_HEADING_LEVEL = 3;
const DEFAULT_HEADING = 'Mehr…';

const ID_PREFIX = 'jki-accordion-';
let globalIdCounter = 1;

const isElementNode = node => node.nodeType === Node.ELEMENT_NODE;

const isNonEmptyTextNode = node => (
	node.nodeType === Node.TEXT_NODE &&
	node.nodeValue &&
	!node.nodeValue.match(/^\s*$/)
);

const isElementOrNonEmptyTextNode = node => isElementNode(node) || isNonEmptyTextNode(node);

function getHeadingFromElement(el) {
	let heading = DEFAULT_HEADING;
	let headingLevel = DEFAULT_HEADING_LEVEL;
	let headingContentElements = [];

	const header = el.querySelector(SELECTOR_HEADER);
	if (header) {
		header.remove();

		const isHeaderHeading = header.matches(SELECTOR_HEADING);
		const headingElement = isHeaderHeading ? header : header.querySelector(SELECTOR_HEADING);

		if (!isHeaderHeading) {
			headingElement.remove();
			headingContentElements = [...header.childNodes].filter(isElementOrNonEmptyTextNode);
		}

		heading = headingElement ? headingElement.innerHTML : header.innerHTML;
		if (headingElement) {
			headingLevel = getHeadingLevelFromElement(headingElement) || DEFAULT_HEADING_LEVEL;
		}
	}
	return {
		heading: heading,
		headingLevel: headingLevel,
		headingContentElements: headingContentElements,
	};
}

/** @typedef {{
 *     uuid: string,
 *     heading: string,
 *     headingLevel: number,
 *     contentElements: Element[],
 *     focused: boolean,
 *  }} CEAccordionItem */

function renderAccordion(elements) {
	// add new wrapper (react root) before first element of group
	const wrapperElement = document.createElement('div');
	wrapperElement.classList.add('jki-accordion__wrapper');
	elements[0].parentNode.insertBefore(wrapperElement, elements[0]);

	/** @type {CEAccordionItem[]} items */
	const items = [];

	/** @type {CEAccordionItem} currentItem */
	let currentItem;
	elements.forEach(el => {
		let focused = false;
		if (location.hash) {
			if (el.matches(location.hash) || el.querySelector(location.hash)) {
				focused = true;
			}
		}

		if (el.classList.contains(CLASSNAME_SUB)) {
			if (currentItem) {
				currentItem.contentElements.push(el);
				if (focused) {
					currentItem.focused = true;
				}
			}
		} else {
			const {heading, headingLevel, headingContentElements} = getHeadingFromElement(el);

			el.remove();

			currentItem = {
				uuid: ID_PREFIX + (globalIdCounter++),
				heading: heading,
				headingLevel: headingLevel,
				headingContentElements: headingContentElements,
				contentElements: [el],
				focused: focused,
			};
			items.push(currentItem);
		}
	});

	ReactDOM.render(<ContentEffectAccordion allowZeroExpanded={true} items={items} />, wrapperElement);
}

export function render() {
	findGroupsMatching(document.body, SELECTOR)
		.forEach(group => renderAccordion(group));
}
